<template>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <c-table
      ref="tableImpr"
      title="개선 목록"
      :columns="gridImpr.columns"
      gridHeight="520px"
      :data="data.imprList"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
      :editable="editable"
      rowKey="chmDangerJournalItemId"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip outline square
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <div v-if="data.chmDangerJournalId">
          <q-btn-group outline class="ColumInnerBtnGroup">
            <q-btn
              v-if="editable && !disabled"
              icon="add"
              color="red-6"
              text-color="white"
              class="ColumInnerBtn"
              align="center"
              @click.prevent="innerBtnClicked(col, props, '1')">
              <q-tooltip>
                개선요청등록
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="editable && !disabled"
              icon="add"
              color="light-blue"
              text-color="white"
              class="ColumInnerBtn"
              align="center"
              @click.prevent="innerBtnClicked(col, props, '2')">
              <q-tooltip>
                즉시조치등록
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </div>
      </template>
    </c-table>
  <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'danger-journal-impr',
  props: {
    data: {
      type: Object,
      default: () => ({
        imprList: [],
        chmDangerCompleteFlag: '',
        sysApprovalRequestId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      colorItems: [],
      gridImpr: {
        columns: [
          {
            name: 'chmDangerItemName',
            field: 'chmDangerItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:60%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '200px'
      },
    };
  },
  computed: {
    disabled() {
     return this.data.chmDangerCompleteFlag === 'Y' || this.data.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      // role setting
      this.editable = this.$route.meta.editable;
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      if (btn === '1') {
        this.popupOptions.title = '개선요청';
        this.popupOptions.param = {
          requestContents: props.row.chmDangerItemName,
          relationTableKey: props.row.chmDangerJournalItemId,
          ibmTaskTypeCd: 'ITT0000085',
          ibmTaskUnderTypeCd: 'ITTU000140'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: props.row.chmDangerItemName,
          relationTableKey: props.row.chmDangerJournalItemId,
          ibmTaskTypeCd: 'ITT0000085',
          ibmTaskUnderTypeCd: 'ITTU000140'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$emit('getDetail');
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선요청';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail');
    },
  }
};
</script>
