var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-lg-12 col-md-12 col-sm-12" },
    [
      _c("c-table", {
        ref: "tableImpr",
        attrs: {
          title: "개선 목록",
          columns: _vm.gridImpr.columns,
          gridHeight: "520px",
          data: _vm.data.imprList,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          hideBottom: true,
          editable: _vm.editable,
          rowKey: "chmDangerJournalItemId",
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                  ? _vm._l(
                      _vm.$comm.ibmTagItems(props.row),
                      function (item, index) {
                        return _c(
                          "q-chip",
                          {
                            key: index,
                            staticStyle: { "margin-bottom": "4px !important" },
                            attrs: {
                              outline: "",
                              square: "",
                              color: item.color,
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkClick(item, props.row)
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(item.title) + " "),
                            _c("c-assess-tooltip", {
                              attrs: {
                                ibmTooltip: item.ibmTooltip,
                                ibmClassCd: item.ibmClassCd,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
                _vm.data.chmDangerJournalId
                  ? _c(
                      "div",
                      [
                        _c(
                          "q-btn-group",
                          {
                            staticClass: "ColumInnerBtnGroup",
                            attrs: { outline: "" },
                          },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c(
                                  "q-btn",
                                  {
                                    staticClass: "ColumInnerBtn",
                                    attrs: {
                                      icon: "add",
                                      color: "red-6",
                                      "text-color": "white",
                                      align: "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.innerBtnClicked(
                                          col,
                                          props,
                                          "1"
                                        )
                                      },
                                    },
                                  },
                                  [_c("q-tooltip", [_vm._v(" 개선요청등록 ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.editable && !_vm.disabled
                              ? _c(
                                  "q-btn",
                                  {
                                    staticClass: "ColumInnerBtn",
                                    attrs: {
                                      icon: "add",
                                      color: "light-blue",
                                      "text-color": "white",
                                      align: "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.innerBtnClicked(
                                          col,
                                          props,
                                          "2"
                                        )
                                      },
                                    },
                                  },
                                  [_c("q-tooltip", [_vm._v(" 즉시조치등록 ")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }